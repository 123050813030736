import {apiPost} from '@api/api'
import {ApiRes} from '@type/api'

export interface IResPostPayPro {
    lot_id: string
    currency: string
    totalFee: string
    prepayId: string
    terminalType: string
    expireTime: number
    qrcodeLink: string
    qrContent: string
    checkoutUrl: string
    deeplink: string
    universalUrl: string
}

export const apiPostPayPro = async (bundleId?: string): Promise<ApiRes<IResPostPayPro>> => {
    return await apiPost('pro/pay', {
        pay_method: 'binance-pay',
        bundle_id: bundleId,
    })
}
