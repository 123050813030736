import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'

export const getPaymentTransactions = async (
    action?: string,
    limit?: number,
    skip?: number,
): Promise<ApiRes<IWalletTransaction[]>> => {
    return await apiGet(`payment/transactions?action=${action}&limit=${limit}&skip=${skip ? skip : 0}`)
}

export interface IWalletTransaction {
    id: string
    user_id: string
    wallet_id: string
    action: string
    coin: string
    amount: number
    balance_after: number
    action_parent_id: string
    extra: string
    created_at: string
}
