import React, {Children, cloneElement, createContext, PropsWithChildren, ReactElement} from 'react'
import BreadcrumbItem, {IBreadcrumbItemProps} from '@component/breadcrumb/BreadcrumbItem'

interface IBreadcrumbContext {}

const BreadcrumbContext = createContext<IBreadcrumbContext | undefined>(undefined)

interface IBreadcrumbComposition {
    Item: React.FC<IBreadcrumbItemProps>
}

interface IProps {
    children?: any
}

const Breadcrumb: React.FC<IProps> & IBreadcrumbComposition = ({children}) => {
    return (
        <BreadcrumbContext.Provider value={{}}>
            <div className={'flex gap-x-[5px]'}>
                {Children.map(children, (child, index) => {
                    const item = child as ReactElement<PropsWithChildren<IBreadcrumbItemProps>>

                    if (item.type === BreadcrumbItem) {
                        const isLastElement = index === children.length - 1
                        return cloneElement(item, {...item.props, _isLastItem: isLastElement})
                    } else {
                        return item
                    }
                })}
            </div>
        </BreadcrumbContext.Provider>
    )
}

Breadcrumb.Item = BreadcrumbItem

export default Breadcrumb
