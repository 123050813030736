import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'

export interface IProBoot {
    pay_methods: string[]
    bundles: IProPlanBundle[]
}
export interface IProPlanBundle {
    bundle_id: string
    price: number
    period: number
}

export const apiGetProBoot = async (): Promise<ApiRes<IProBoot>> => {
    return await apiGet(`pro/pay`)
}
