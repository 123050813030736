import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'
export interface MeRankData {
    ranks: MeRankMap
    simple_graph: number[]
}

type MeRankMap = {
    [key in '1' | '30' | '1000']: {
        rank: number
        score: number
    }
}

export const apiGetMeRank = async (): Promise<ApiRes<MeRankData>> => {
    return await apiGet(`me/rank`)
}
