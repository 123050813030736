import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'

export interface ISidebar {
    user_id: string
    username: string
    thumbnail: string
    key_id: string
    key_name: string
    flags: string[]
    status: string
    following: number
    followers: number
    privacy_level: number
    is_following: boolean
    can_copy: boolean
    joined_at: string
    views: number
    current_balance: number
    all_time_profit_rate: number
    copy_earning: number
    leader_earning: number
    wallet_balance: number
    is_pro?: boolean

    is_paid_leader?: boolean
}

export interface IApiBaseProps {
    cookie?: any
}

export interface IApiGetSidebarKeyIdProps extends IApiBaseProps {}

export const apiGetSidebarUserID = async (
    user_id: string,
    props?: IApiGetSidebarKeyIdProps,
): Promise<ApiRes<ISidebar>> => {
    return await apiGet(`user/sidebar?user_id=${user_id}`, {cookies: props?.cookie})
}

export const apiGetSidebarKeyID = async (
    key_id: string,
    props?: IApiGetSidebarKeyIdProps,
): Promise<ApiRes<ISidebar>> => {
    return await apiGet(`user/sidebar?key_id=${key_id}`, {cookies: props?.cookie})
}
