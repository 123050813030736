import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {DayDuration} from '@type/time'
import {GraphPeriodOptionType} from '@hook/useChartPeriod'

export interface IUserGraph {
    day: number
    score: number
    earn: number
    balance: number
    adjusted_profit?: number
    adjusted_pnl?: number
}

export const apiGetUserGraph = async (
    id: string,
    duration: DayDuration | GraphPeriodOptionType,
): Promise<ApiRes<IUserGraph[]>> => {
    return await apiGet(`pgraph/${id}?d=${duration}`)
}
