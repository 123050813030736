import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {createUrl} from '@util/urls'

export interface IAddress {
    address: string
    chain: string
}

export type TGetDepositAddressKind = 'user-wallet' | 'lot-pro' | 'invoice'
export const apiGetDepositAddress = async (
    kind: TGetDepositAddressKind,
    blockchain: string,
): Promise<ApiRes<IAddress>> => {
    return apiGet(createUrl(`/user/address/${blockchain.toLowerCase()}`, {kind: kind}))
}

export const apiGetDepositAddresses = async (): Promise<ApiRes<IAddress[]>> => {
    return apiGet(`user/addresses`)
}
