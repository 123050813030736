import React, {useState} from 'react'
import {useTranslation} from 'next-i18next'
import {Disclosure} from '@headlessui/react'
import IconDown from '@svg/common/ic_btn_down.svg'
import IconUp from '@svg/common/ic_btn_up.svg'
import Text from '@component/Text/Text'

export interface IFAQProps {
    title?: string
    children?: any
    containerClassName?: string
    className?: string
    renderDownArrowIcon?: JSX.Element
    renderUpArrowIcon?: JSX.Element
    accordionClassName?: string
    titleClassName?: string
    isOpen?: boolean
}

const WizBarAccordion: React.FC<IFAQProps> = ({
    title,
    children,
    containerClassName,
    className,
    titleClassName,
    accordionClassName,
    renderDownArrowIcon,
    renderUpArrowIcon,
    isOpen,
}) => {
    const {t} = useTranslation()

    const [openState, setOpenState] = useState(isOpen ?? false)

    return (
        <div className={`${containerClassName}`}>
            <Disclosure defaultOpen={openState}>
                {({open}) => (
                    <div>
                        <Disclosure.Button
                            className={`text-left bg-gray09 dark:bg-dark_gray09 py-[25px] px-[30px] rounded-[5px] w-full ${accordionClassName}`}>
                            <div className={'flex items-center justify-between'}>
                                <Text className={`text-body2 ${titleClassName}`}>{title}</Text>
                                {open ? (
                                    renderUpArrowIcon ? (
                                        renderUpArrowIcon
                                    ) : (
                                        <IconUp width={18} className={'fill-white opacity-60 flex-none'} />
                                    )
                                ) : renderDownArrowIcon ? (
                                    renderDownArrowIcon
                                ) : (
                                    <IconDown width={18} className={'fill-white opacity-60 flex-none'} />
                                )}
                            </div>
                        </Disclosure.Button>
                        <Disclosure.Panel>
                            <div className={`text-body2 pt-[20px] px-[30px] ${className}`}>{children}</div>
                        </Disclosure.Panel>
                    </div>
                )}
            </Disclosure>
        </div>
    )
}

export default WizBarAccordion
