import React from 'react'
import {useTranslation} from 'next-i18next'
import IconDown from '@svg/common/ic_btn_down.svg'
import IconUp from '@svg/common/ic_btn_up.svg'
import Text from '@component/Text/Text'
import WizIcon from '@component/images/WizIcon'

export interface IFAQProps {
    open: boolean
    title?: string
    children?: any
    renderRightIcon?: any
    className?: string
    iconClassName?: string
    renderTitleLeft?: JSX.Element
    titleContainerClassName?: string
    titleClassName?: string
    contentClassName?: string
    onToggle?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const WizAccordion: React.FC<IFAQProps> = ({
    open,
    title,
    children,
    renderRightIcon,
    renderTitleLeft,
    className,
    iconClassName,
    titleContainerClassName,
    titleClassName,
    contentClassName,
    onToggle,
}) => {
    const {t} = useTranslation()

    return (
        <div className={`rounded-[5px] ${className}`}>
            <div>
                <div
                    className={`text-left w-full flex gap-[10px] items-center  cursor-pointer ${titleContainerClassName}`}
                    onClick={e => onToggle(e)}>
                    {renderRightIcon}
                    <div className={'flex items-center justify-between flex-1 gap-x-[10px]'}>
                        {renderTitleLeft}
                        <div className={'flex-1'}>
                            <Text className={` ${titleClassName}`}>{title}</Text>
                        </div>
                        {open ? (
                            <WizIcon
                                backgroundColorClassName={
                                    'p-[7px] hover:bg-transparent01 dark:hover:bg-dark_transparent01'
                                }>
                                <IconUp
                                    className={`w-[18px] fill-gray01 dark:fill-dark_gray01 flex-none ${iconClassName}`}
                                />
                            </WizIcon>
                        ) : (
                            <WizIcon
                                backgroundColorClassName={
                                    'p-[7px] hover:bg-transparent01 dark:hover:bg-dark_transparent01'
                                }>
                                <IconDown
                                    className={`w-[18px] fill-gray01 dark:fill-dark_gray01 flex-none ${iconClassName}`}
                                />
                            </WizIcon>
                        )}
                    </div>
                </div>
                <div
                    className={`${
                        open ? 'max-h-[auto] opacity-1' : 'max-h-0 opacity-0'
                    } ${contentClassName} transition-all duration-300 ease-in-out`}>
                    {open ? children : null}
                </div>
            </div>
        </div>
    )
}

export default WizAccordion
