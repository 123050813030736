import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {DayDuration} from '@type/time'
import {IApiBaseProps} from '@api/user/sidebar/getSidebar'

export interface ICopierGraph {
    day: number
    earning: number
    balance: number
}

export const apiGetCopierGraphForUser = async (
    user_id: string,
    duration?: DayDuration.Month | DayDuration.Lifetime,
    props?: IApiBaseProps,
): Promise<ApiRes<ICopierGraph[]>> => {
    return await apiGet(`copier/graph?user_id=${user_id}&duration=${duration}`, {cookies: props?.cookie})
}

export const apiGetCopierGraphForKey = async (
    key_id: string,
    duration?: DayDuration.Month | DayDuration.Lifetime,
    props?: IApiBaseProps,
): Promise<ApiRes<ICopierGraph[]>> => {
    return await apiGet(`copier/graph?key_id=${key_id}&duration=${duration}`, {cookies: props?.cookie})
}
