import React from 'react'
import Text from '@component/Text/Text'
import Link from 'next/link'
import IconNext from '@svg/common/ic_btn_next.svg'
import {createLog} from '@util/logs'

export interface IBreadcrumbItemProps {
    text: string
    url?: string
    _isLastItem?: boolean
}

const BreadcrumbItem: React.FC<IBreadcrumbItemProps> = ({text, url, _isLastItem}) => {
    const getTextStyle = () => {
        if (_isLastItem) {
            return 'text-ti3'
        } else {
            return 'text-body3 underline'
        }
    }
    return (
        <>
            <Link href={url ?? '#'} onClick={() => createLog('event', 'breadcrumb_item_clicked', {path: url})}>
                <Text className={`${getTextStyle()} text-gray02 dark:text-dark_gray02 ${!url && 'cursor-default'}`}>
                    {text}
                </Text>
            </Link>
            {!_isLastItem && <IconNext className={'w-[10px] fill-gray02 dark:fill-dark_gray02 flex-none'} />}
        </>
    )
}

export default BreadcrumbItem
