import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'

export interface MiniProfile {
    user_id: string
    win: number
    loss: number
    privacy: number
    balance_app: number
    mdd: number
    hold_avg: number
    tf: number
}

export const apiGetMiniProfile = async (id: string): Promise<ApiRes<MiniProfile>> => {
    return await apiGet(`user/mini/${id}`)
}
