import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {DayDuration} from '@type/time'
import {GraphPeriodOptionType} from '@hook/useChartPeriod'

export interface IKeyGraph {
    day: number
    score: number
    earning: number
    balance: number
}

export const apiGetKeyGraph = async (id: string, duration: GraphPeriodOptionType): Promise<ApiRes<IKeyGraph[]>> => {
    return await apiGet(`key/graph/${id}?duration=${duration}`)
}
