import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {createUrl} from '@util/urls'

export interface IWithdrawHistory {
    id: string
    wallet_id: string
    coin: string
    blockchain: string
    address: string
    amount: number
    fee: number
    status: string
    chain_tx_id: string
    chain_timestamp: number
    created_at: string
    updated_at: string
    reason: string
    verified: boolean
}

export const apiGetWithdrawHistory = async (limit?: number, before?: number): Promise<ApiRes<IWithdrawHistory[]>> => {
    return await apiGet(createUrl('payment/crypto/withdraw/history', {limit: limit, before: before}))
}
