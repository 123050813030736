import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'

export interface INetwork {
    key: string
    name: string
    logo: string
}

export interface IDepositCurrency {
    symbol: string
    name: string
    logo: string
}

export interface IDepositData {
    networks: INetwork[]
    currencies: IDepositCurrency[]
}

export const apiGetDepositCurrencies = async (): Promise<ApiRes<IDepositData>> => {
    return apiGet(`payment/crypto/deposit/currencies`)
}
