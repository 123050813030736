import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {createUrl} from '@util/urls'

export interface IProConfirmStatus {
    id: string
    user_id: string
    product: string
    product_id: string
    prepay_id: string
    status: string
    amount: number
    created_at: string
    updated_at: string
    expire_at: string
}

export const apiGetProPayConfirmStatus = async (id: string, method: string): Promise<ApiRes<IProConfirmStatus>> => {
    return await apiGet(createUrl(`pro/pay/confirm`, {id: id, method: method}))
}
