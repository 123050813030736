import {ApiRes} from '@type/api'
import {apiPost} from '@api/api'

interface PayInvoiceRes {
    remaining: number
}

export const apiPayInvoice = async (invoice_id: string): Promise<ApiRes<PayInvoiceRes>> => {
    return apiPost('payment/invoice/pay', {invoice_id})
}
